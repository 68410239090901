import { Link, Button, Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import i18next from 'i18next';
import Aos from 'aos';
import 'aos/dist/aos.css';
const getLanguage = () => i18next.language || window.localStorage.i18nextLng;

const AboutUs = ({ about, items }) => {
	const { t } = useTranslation();

	useEffect(() => {
		Aos.init({
			offset: 200,
			duration: 600,
			easing: 'ease-in-sine',
			delay: 100,
		});
	}, []);
	return (
		<Box name="AboutUs" py={15} sx={{ backgroundColor: '#F7F4F2' }}>
			<Container maxWidth="lg">
				<Grid container spacing={2}>
					<Grid item data-aos="slide-up" xs={12}>
						<Typography variant="h3" color="#524fa1" fontWeight="600">
							{about && about[i18next.language].title}
						</Typography>
					</Grid>
					<Grid item data-aos="slide-up" xs={12}>
						<Typography>{about && about[i18next.language].description}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={3} justifyContent="center" alignItems="center">
							{(items || []).map((item) => (
								<Grid item data-aos={getLanguage() === 'en' ? 'flip-right' : 'flip-left'} xs={12} md={4} key={item.id}>
									<Grid container spacing={3} justifyContent="center" alignItems="center" pt={10} pb={5}>
										<Grid item xs={12} align="center">
											<img src={item.image} style={{ width: 64 }} alt={item.title} />
										</Grid>
										<Grid item xs={12}>
											<Typography variant="h5" align="center">
												{item[i18next.language === 'en' ? 'name' : 'nameAr']}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography align="center">{item[i18next.language === 'en' ? 'description' : 'descriptionAr']}</Typography>
										</Grid>
										{item.button && item.link && (
											<Grid item xs={12} align="center">
												{console.log(item, item['link'], item[i18next.language === 'ar' ? 'linkAr' : 'link'])}
												<Button
													target="_blank"
													style={{ textDecoration: 'none' }}
													component={Link}
													variant="contained"
													href={item[i18next.language === 'ar' ? 'linkAr' : 'link']}
												>
													{item[i18next.language === 'ar' ? 'buttonAr' : 'button']}
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};
export default AboutUs;
