import {
	Avatar,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Input,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { addDoc, collection, deleteDoc, doc, getDocs, setDoc } from '@firebase/firestore';
import { db } from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from '@firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@mui/lab';
import { AddCircleOutline, Delete, Edit } from '@mui/icons-material';

export default function AboutUsSection({ isLoading: isSubmitLoading, defaultValue, onSubmit }) {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isAddMode, setIsAddMode] = useState(false);
	const [entries, setEntries] = useState([]);
	const [section, setSection] = useState(defaultValue);

	const content = [
		{
			key: 'en',
			title: t('English Title:'),
			titleLabel: t('English Title:'),
			description: t('English Description:'),
			descriptionLabel: t('English Description:'),
		},
		{
			key: 'ar',
			title: t('Arabic Title:'),
			titleLabel: t('Arabic Title:'),
			description: t('Arabic Description:'),
			descriptionLabel: t('Arabic Description:'),
		},
	];

	const [selectedEntry, setSelectedEntry] = useState({
		order: 0,
		title: '',
		titleAr: '',
		description: '',
		descriptionAr: '',
		link: '',
		linkAr: '',
		buttonText: '',
		buttonTextAr: '',
	});
	const [selectedFile, setSelectedFile] = useState();

	const deleteEntry = async (member) => {
		console.log('deleteEntry', member);

		const storage = getStorage();

		// Create a reference to the file to delete
		const desertRef = ref(storage, member.image);

		// Delete the file
		await deleteObject(desertRef);
		await deleteDoc(doc(db, 'members', member.id));

		setEntries(entries.filter((m) => m.id !== member.id));
	};

	const manageEntry = async () => {
		setIsLoading(true);
		let image = selectedEntry.image;
		if (selectedFile) {
			if (image) {
				const storage = getStorage();
				// Create a reference to the file to delete
				//	const imageToDeleteRef = ref(storage, image);
				// Delete the file
				//await deleteObject(imageToDeleteRef);
			}

			image = await uploadFile(selectedFile);
		}

		if (isEditMode) {
			await setDoc(doc(db, 'about', selectedEntry.id), { ...selectedEntry, image });
			setEntries(entries.map((entry) => (entry.id === selectedEntry.id ? { ...selectedEntry, image, updatedAt: new Date() } : entry)));
		}

		if (isAddMode) {
			const payload = { ...selectedEntry, image, createdAt: new Date(), updatedAt: new Date() };
			const newDocRef = await addDoc(collection(db, 'about'), { ...selectedEntry, image, createdAt: new Date(), updatedAt: new Date() });
			entries.push({ ...payload, id: newDocRef.id });
		}

		handleClose();
	};

	const handleClose = () => {
		setIsLoading(false);
		setSelectedFile(undefined);
		setSelectedEntry({
			order: 0,
			title: '',
			titleAr: '',
			description: '',
			descriptionAr: '',
			link: '',
			linkAr: '',
			buttonText: '',
			buttonTextAr: '',
		});

		setIsEditMode(false);
		setIsAddMode(false);
	};

	const uploadFile = async (file) => {
		return new Promise((resolve, reject) => {
			const storage = getStorage();

			// Upload file and metadata to the object 'images/mountains.jpg'
			const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			const storageRef = ref(storage, `images/${uuidv4()}.${extension}`);
			const uploadTask = uploadBytesResumable(storageRef, file, {});

			// Listen for state changes, errors, and completion of the upload.
			return uploadTask.on(
				'state_changed',
				(snapshot) => {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log('Upload is ' + progress + '% done');
				},
				(error) => {
					console.log('upload file error', error);
					reject(error);
				},
				async () => {
					// Upload completed successfully, now we can get the download URL
					const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
					resolve(downloadURL);
				}
			);
		});
	};

	useEffect(() => {
		getDocs(collection(db, 'about')).then((snap) => {
			setEntries(snap.docs.map((d) => ({ ...d.data(), id: d.id })));
			setIsLoading(false);
		});
	}, []);

	return (
		<Grid container spacing={2} justifyContent="flex-end">
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h6">{t('Editing Posts Section')}</Typography>
							</Grid>
							{content.map((item) => (
								<Grid key={item.key} item xs={12} md={6}>
									<Stack direction="column" spacing={2}>
										<Typography>{item.title}</Typography>
										<TextField
											label={item.titleLabel}
											value={section[item.key].title}
											onChange={({ target: { value } }) => setSection({ ...section, [item.key]: { title: value } })}
											variant="outlined"
											fullWidth
										/>

										<Typography>{item.description}</Typography>
										<TextField
											label={item.descriptionLabel}
											value={section[item.key].description}
											onChange={({ target: { value } }) => setSection({ ...section, [item.key]: { description: value } })}
											variant="outlined"
											fullWidth
										/>
									</Stack>
								</Grid>
							))}
							<Grid item xs={12}>
								<LoadingButton variant="outlined" onClick={() => onSubmit({ about: section })}>
									{t('Save')}
								</LoadingButton>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid item>
				<IconButton onClick={() => setIsAddMode(true)}>
					<AddCircleOutline />
				</IconButton>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography variant="h6">Icon</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Order</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Title</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Arabic Title</Typography>
									</TableCell>
									{/* <TableCell>
										<Typography variant="h6">Description</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Arabic Description</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Button Text</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Arabic Button Text</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Button Link</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Arabic Button Link</Typography>
									</TableCell> */}
									<TableCell>
										<Typography variant="h6">Edit</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">Delete</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{entries.map((entry) => (
									<TableRow key={entry.id}>
										<TableCell>
											<Avatar src={entry.image} alt={entry.name} />
										</TableCell>
										<TableCell>
											<Typography>{entry.order}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{entry.name}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{entry.nameAr}</Typography>
										</TableCell>
										{/* <TableCell>
											<Typography>{entry.description}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{entry.descriptionAr}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{entry.button}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{entry.buttonAr}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{entry.link}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{entry.linkAr}</Typography>
										</TableCell> */}
										<TableCell>
											<IconButton
												onClick={() => {
													setIsEditMode(true);
													setSelectedEntry(entry);
												}}
											>
												<Edit />
											</IconButton>
										</TableCell>
										<TableCell>
											<IconButton
												color="error"
												onClick={() => {
													deleteEntry(entry);
												}}
											>
												<Delete />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</Grid>
			<Dialog open={isEditMode || isAddMode} fullWidth onClose={handleClose}>
				<DialogTitle>{isEditMode ? 'Edit Member' : 'Add Member'}</DialogTitle>
				<Divider />
				<DialogContent>
					<Stack spacing={2}>
						<TextField
							fullWidth
							label="Order"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, order: value })}
							type="number"
							value={selectedEntry.order}
						/>
						<TextField
							fullWidth
							label="Name"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, name: value })}
							value={selectedEntry.name}
						/>
						<TextField
							fullWidth
							label="Arabic Name"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, nameAr: value })}
							value={selectedEntry.nameAr}
						/>
						<TextField
							fullWidth
							label="Description"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, description: value })}
							value={selectedEntry.description}
						/>
						<TextField
							fullWidth
							label="Arabic Description"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, descriptionAr: value })}
							value={selectedEntry.descriptionAr}
						/>
						<TextField
							fullWidth
							label="Button Text"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, button: value })}
							value={selectedEntry.button}
						/>
						<TextField
							fullWidth
							label="Arabic Button Text"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, buttonAr: value })}
							value={selectedEntry.buttonAr}
						/>
						<TextField
							fullWidth
							label="Button Link"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, link: value })}
							value={selectedEntry.link}
						/>
						<TextField
							fullWidth
							label="Arabic Button Link"
							onChange={({ target: { value } }) => setSelectedEntry({ ...selectedEntry, linkAr: value })}
							value={selectedEntry.linkAr}
						/>
						<Stack direction="row" alignItems="center" spacing={2}>
							<Avatar src={selectedEntry.image} alt={selectedEntry.name} />
							<input type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
						</Stack>
					</Stack>
				</DialogContent>
				<DialogActions>
					<LoadingButton loading={isLoading} onClick={manageEntry}>
						Save
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}
